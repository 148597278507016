<template>
    <div class="fluid">
        <div class="card">
            <h5>Lüftungsparameter</h5>
            <DataTable
                :value="lValues"
                editMode="cell"
                class="editable-cells-table"
                dataKey="index"
                v-model:editingRows="editingRows"
                @row-edit-save="onRowEditSave"
                responsiveLayout="stack"
                rowGroupMode="subheader"
                groupRowsBy="metadata.department"
                sortMode="single"
                sortField="index"
                :sortOrder="1"
            >
                <template #empty> Nichts gefunden </template>
                <Column field="metadata.category" header="Kategorie"></Column>
                <Column field="metadata.department" header="Anlage" style="width: 8%; min-width: 6rem"></Column>
                <Column field="metadata.name" header="Name"> </Column>
                <Column field="value.value" header="Parameter"></Column>
                <Column field="value.type" header="Verhalten" style="width: 10%; min-width: 8rem" class="text-center"> </Column>
                <Column field="value.value" header="Wert" style="width: 15%; min-width: 12rem" class="text-center">
                    <template #body="{ data }">
                        {{ data.value.value + data.value.unit }}
                    </template>
                    <template #editor="{ data }">
                        <!-- <InputText v-model="data[field]" autofocus />{{ data.unit }} -->
                        <InputNumber
                            :id="data.index"
                            v-model="data.value.value"
                            showButtons
                            buttonLayout="horizontal"
                            decrementButtonClass="p-button-danger"
                            incrementButtonClass="p-button-success"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                            mode="decimal"
                            :suffix="data.value.unit"
                            :step="0.5"
                            class="enat-middle"
                        />
                    </template>
                </Column>
                <template #groupheader="slotProps">
                    <template v-if="slotProps.data.metadata.category === 'Heizung'">
                        <span class="icon"><span class="mif-fire"></span></span>
                    </template>
                    <template v-if="slotProps.data.metadata.category === 'ventilation'">
                        <span class="icon"><span class="mif-snowflake"></span></span>
                    </template>
                    <!-- <img
            :alt="slotProps.data.kategorie"
            src="https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
            width="32"
            style="vertical-align: middle"
          /> -->
                    <span class="image-text">Anlage {{ slotProps.data.metadata.department }}</span>
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script>
// import ProductService from "../helpers";
import store from '@/store';
// import SocketioService from '@/services/socketioService.js';
import mainService from '@/services/mainService.js';

export default {
    name: 'vents',
    data() {
        return {
            lValues: [],
            editingRows: [],
            columns: null,
            products: null,
            statuses: [
                { label: 'In Stock', value: 'INSTOCK' },
                { label: 'Low Stock', value: 'LOWSTOCK' },
                { label: 'Out of Stock', value: 'OUTOFSTOCK' },
            ],
        };
    },
    productService: null,
    computed: {
        values: function () {
            return store.getters.getValues;
        },
    },
    created() {
        // this.productService = new ProductService();
    },
    mounted() {
        this.getParameter();
        this.loadValues();
    },
    methods: {
        getParameter() {
            //SocketioService.loadStatusIo();
            mainService
                .getParameterList()
                .then((response) => {
                    // console.log('server: ', response.data)
                    this.products = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        loadValues() {
            console.log(this.values);
            const _obj = JSON.parse(JSON.stringify(this.values));
            _obj.forEach((entry, index1) => {
                if (entry.metadata.category === 'ventilation') {
                    console.log(entry);
                    if (entry.value.type === 'OB') {
                        if (entry.children.length > 0) {
                            entry.children.forEach((child) => {
                                console.log(child);
                                let param = null;
                                let unit = null;
                                let factor = null;
                                let index = null;
                                if (child.displayName === 'Stellung_MIN_LUV') {
                                    param = 'Absolute Minimum-Begrenzung LUV-Seite';
                                    unit = '%';
                                    factor = 10;
                                    index = index1+1;
                                }
                                if (child.displayName === 'Stellung_MIN_LEE') {
                                    param = 'Absolute Minimum-Begrenzung LEE-Seite';
                                    unit = '%';
                                    factor = 10;
                                    index = index1+2;
                                }
                                if (child.displayName === 'Stellung_MAX_LUV') {
                                    param = 'Absolute Maximum-Begrenzung LUV-Seite';
                                    unit = '%';
                                    factor = 10;
                                    index = index1+3;
                                }
                                if (child.displayName === 'Stellung_MAX_LEE') {
                                    param = 'Absolute Maximum-Begrenzung LEE-Seite';
                                    unit = '%';
                                    factor = 10;
                                    index = index1+4;
                                }
                                if (child.displayName === 'Stellung_MAX_ZEIT_VON') {
                                    param = 'Zeitliche Maximum-Begrenzung VON Uhrzeit';
                                    unit = '';
                                    factor = 1;
                                    index = index1+5;
                                }
                                if (child.displayName === 'Stellung_MAX_ZEIT_BIS') {
                                    param = 'Zeitliche Maximum-Begrenzung BIS Uhrzeit';
                                    unit = '';
                                    factor = 1;
                                    index = index1+6;
                                }
                                if (child.displayName === 'Stellung_MAX_ZEIT_LUV') {
                                    param = 'Zeitliche Maximum-Begrenzung LUV-Seite';
                                    unit = '%';
                                    factor = 10;
                                    index = index1+7;
                                }
                                if (child.displayName === 'Stellung_MAX_ZEIT_LEE') {
                                    param = 'Zeitliche Maximum-Begrenzung LEE-Seite';
                                    unit = '%';
                                    factor = 10;
                                    index = index1+8;
                                }
                                if (child.displayName === 'Stellung_Wind_LUV_ab') {
                                    param = 'Windabhängige Maximum-Begrenzung LUV-Seite ab Windgeschwindigkeit';
                                    unit = 'm/s';
                                    factor = 100;
                                    index = index1+9;
                                }
                                if (child.displayName === 'Stellung_Wind_LUV') {
                                    param = 'Windabhängige Maximum-Begrenzung LUV-Seite';
                                    unit = '%';
                                    factor = 10;
                                    index = index1+10;
                                }
                                if (child.displayName === 'Stellung_Wind_LEE_ab') {
                                    param = 'Windabhängige Maximum-Begrenzung LEE-Seite ab Windgeschwindigkeit';
                                    unit = 'm/s';
                                    factor = 100;
                                    index = index1+11;
                                }
                                if (child.displayName === 'Stellung_Wind_LEE') {
                                    param = 'Windabhängige Maximum-Begrenzung LEE-Seite';
                                    unit = '%';
                                    factor = 10;
                                    index = index1+12;
                                }
                                if (child.displayName === 'Stellung_REGEN_LUV') {
                                    param = 'Regen-Begrenzung LUV-Seite';
                                    unit = '%';
                                    factor = 10;
                                    index = index1+13;
                                }
                                if (child.displayName === 'Stellung_REGEN_LEE') {
                                    param = 'Regen-Begrenzung LEE-Seite';
                                    unit = '%';
                                    factor = 10;
                                    index = index1+14;
                                }
                                if (param !== null && factor !== null) {
                                    const _obj2 = {
                                        index: index,
                                        metadata: {
                                            department: entry.metadata.department,
                                            category: 'Lüftungsparameter',
                                            name: param
                                        },
                                        value : {
                                            value: child.value/factor,
                                            unit: unit
                                        }
                                    };
                                    this.lValues.push(_obj2);
                                }
                            });
                        }
                    } else {
                        this.lValues.push(entry);
                    }
                }
            });
            // this.lValues = _obj;
            // console.log(_obj);
            // _obj.map((entry) => {
            //     console.log(entry);
            // });
            // console.log(_obj);
            
        },
        onCellEditComplete(event) {
            let { data, newValue, field } = event;

            switch (field) {
                case 'quantity':
                case 'price':
                    if (this.isPositiveInteger(newValue)) data[field] = newValue;
                    else event.preventDefault();
                    break;

                default:
                    if (newValue.trim().length > 0) data[field] = newValue;
                    else event.preventDefault();
                    break;
            }
        },
        isPositiveInteger(val) {
            let str = String(val);
            str = str.trim();
            if (!str) {
                return false;
            }
            str = str.replace(/^0+/, '') || '0';
            var n = Math.floor(Number(str));
            return n !== Infinity && String(n) === str && n >= 0;
        },
        onRowEditSave(event) {
            let { newData, index } = event;

            this.products2[index] = newData;
        },
        getStatusLabel(status) {
            switch (status) {
                case 'INSTOCK':
                    return 'In Stock';

                case 'LOWSTOCK':
                    return 'Low Stock';

                case 'OUTOFSTOCK':
                    return 'Out of Stock';

                default:
                    return 'NA';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
    padding-top: 0;
    padding-bottom: 0;
}
::v-deep(.editable-cells-table td.p-cell-editing input) {
    width: 80%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 700;
    font-size: 110%;
    color: yellow;
}
::v-deep(.editable-cells-table td.p-cell-editing .enat-middle input) {
    text-align: center;
}
::v-deep(.editable-cells-table td.p-editable-column .p-column-title) {
    padding-right: 1em;
}
::v-deep(.image-text) {
    font-weight: 700;
    color: yellow;
}
</style>
